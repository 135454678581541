import { Variants, motion } from 'framer-motion';
import React from 'react';

type FadeInSectionProps = {
  duration: number;
  bounce: number;
  viewportAmount?: number;
  initialOffsetY?: number;
  initialOffsetX?: number;
  initialOpacity?: number;
  children: React.ReactNode;
};
const FadeInSection: React.FC<FadeInSectionProps> = (props) => {
  const { duration, bounce, viewportAmount, children } = props;
  const cardVariants: Variants = {
    offscreen: {
      y: props.initialOffsetY || 0,
      x: props.initialOffsetX || 0,
      opacity: props.initialOpacity,
    },
    onscreen: {
      y: 0,
      x: 0,
      transition: { type: 'spring', bounce, duration },
      opacity: 1,
    },
  };
  return (
    <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: viewportAmount }}>
      <motion.div variants={cardVariants}>{children}</motion.div>
    </motion.div>
  );
};
FadeInSection.displayName = 'FadeInSection';
export default FadeInSection;
