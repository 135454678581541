import React, { useEffect, useMemo } from 'react';

import { MOBILE_MENU_WIDGETS_WIDTH } from '../components/layout/constants';

export const useIsMobileView = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(global.window?.innerWidth);

  useEffect(() => {
    const onResize = () => {
      const userAgent = navigator.userAgent || navigator.vendor || (window as any)?.opera;
      const hasMobileUserAgent = /android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream);
      setIsMobile(hasMobileUserAgent);
      setWindowWidth(window.innerWidth);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return useMemo(() => isMobile || windowWidth < MOBILE_MENU_WIDGETS_WIDTH, [isMobile, windowWidth]);
};
