import Link from 'next/link';
import React from 'react';
import { AiOutlineYoutube } from 'react-icons/ai';

type YoutubeLinkProps = React.HTMLProps<HTMLAnchorElement>;
const YoutubeLink: React.FC<YoutubeLinkProps> = React.memo(() => {
  return (
    <Link
      href={'https://www.youtube.com/@angesl1206'}
      target="_blank"
      className="font-semibold flex flex-row items-center gap-2 font-sans group"
    >
      <AiOutlineYoutube size={25} className="text-black transition-colors duration-300 group-hover:text-red-600" />
      <span className="transition-colors duration-300 group-hover:text-red-600">angesl1206</span>
    </Link>
  );
});
YoutubeLink.displayName = 'YoutubeLink';
export default YoutubeLink;
