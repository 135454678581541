import { NextSeoProps } from 'next-seo';

import pageRoutes, { PageRoute } from '../../router/pageRoutes';

const pageSeoConfigs: { [key in PageRoute]: { title: string; description: string } } = {
  [pageRoutes.Index]: {
    title: 'Bellydanza',
    description:
      'Discover the captivating world of belly dance with our professional dancer. Explore performances, private lessons, and group classes to master the art of belly dance.',
  },
  [pageRoutes.About]: {
    title: 'Bellydanza | About',
    description:
      'Learn more about our professional belly dancer, Angie Sanchez. Discover her journey, training, and passion for belly dance. Explore the world of belly dance with Angie.',
  },
  [pageRoutes.Contact]: {
    title: 'Bellydanza | Contact',
    description:
      "Get in touch with us for belly dance class schedules, private lessons, or to book performances. We're here to answer all your inquiries about belly dance.",
  },
  [pageRoutes.Gallery]: {
    title: 'Bellydanza | Gallery',
    description:
      'Explore our belly dance gallery to see captivating performances and events. Discover the beauty and grace of belly dance through our stunning photo gallery.',
  },
};

export const getPageSeoConfig = (pageRoute: PageRoute): NextSeoProps => {
  const { title, description } = pageSeoConfigs[pageRoute];
  const url = pageRoute;
  return { title, description, openGraph: { url, title, description } };
};

export const get404ErrorPageSeoConfig = (): NextSeoProps => {
  const title = 'Page Not Found';
  const description = 'The link you clicked may be broken or the page may have been removed';
  return { title, description };
};

export const get500ErrorPageSeoConfig = (): NextSeoProps => {
  const title = 'Error Page';
  const description = 'An unexpected error occured';
  return { title, description };
};
