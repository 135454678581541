import classnames from 'classnames';
import Link, { LinkProps } from 'next/link';
import React from 'react';

export const PRIMARY_BUTTON_CLASS =
  'font-serif select-none inline-flex items-center justify-center py-2 px-6 bg-black text-pureWhite p-4 text-xl transition-colors duration-300 ease-in-out hover:bg-yellow-300 hover:text-black';

const PrimaryButton: React.FC<React.HTMLProps<HTMLAnchorElement>> = React.memo((props) => {
  const { children, className, ...restProps } = props;
  return (
    <Link className={classnames(PRIMARY_BUTTON_CLASS, className)} {...(restProps as LinkProps)}>
      {children}
    </Link>
  );
});
PrimaryButton.displayName = 'PrimaryButton';
export default PrimaryButton;
