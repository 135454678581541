import Link from 'next/link';
import React from 'react';
import { AiOutlineInstagram } from 'react-icons/ai';

type InstragramLinkProps = React.HTMLProps<HTMLAnchorElement>;
const InstragramLink: React.FC<InstragramLinkProps> = React.memo(() => {
  return (
    <Link
      href={'https://www.instagram.com/ange_bellydance'}
      target="_blank"
      className="font-semibold flex flex-row items-center gap-2 font-sans group"
    >
      <AiOutlineInstagram size={25} className="text-black transition-colors duration-300 group-hover:text-purple" />
      <span className="transition-colors duration-300 group-hover:text-purple">ange_bellydance</span>
    </Link>
  );
});
InstragramLink.displayName = 'InstragramLink';
export default InstragramLink;
