
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import classNames from 'classnames';
import classnames from 'classnames';
import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import Image from 'next/image';
import React from 'react';
import FadeInSection from '../components/animated/FadeInSection';
import InstragramLink from '../components/buttons/InstragramLink';
import PrimaryButton from '../components/buttons/PrimaryButton';
import YoutubeLink from '../components/buttons/YoutubeLink';
import { SiteAssetsCDN } from '../constants';
import { useIsMobileView } from '../hooks/useIsMobileView';
import { useTranslations } from '../hooks/useTranslations';
import { withGlobalDefaultStaticProps } from '../lib/next-hooks/withGlobalDefaultStaticProps';
import { getPageSeoConfig } from '../lib/seo/pageSeoConfigs';
import pageRoutes from '../router/pageRoutes';
import { NextPageWithLayout } from '../types/global-types';
import { UserLocale } from '../types/translation-types';
const HOME_PAGE_IMAGE_WIDTH = 700;
const HOME_PAGE_IMAGE_HEIGHT = 1072;
type HomePageStaticProps = {};
const getStaticProps: GetStaticProps<HomePageStaticProps> = async (context) => {
    const locale = context.locale as UserLocale;
    return withGlobalDefaultStaticProps(context, { props: { locale } });
};
const HomePage: NextPageWithLayout<HomePageStaticProps> = () => {
    const seoConfig = getPageSeoConfig(pageRoutes.Index);
    const translations = useTranslations();
    const isMobileView = useIsMobileView();
    return (<>
      <NextSeo {...seoConfig}/>
      <div className={classnames('flex flex-col-reverse md:flex-row items-start gap-12 w-full', {
            'min-h-[200px]': !isMobileView,
        })}>
        <div className="relative block max-w-full" style={{
            minWidth: isMobileView ? undefined : `${HOME_PAGE_IMAGE_WIDTH}px`,
            minHeight: isMobileView ? undefined : `${HOME_PAGE_IMAGE_HEIGHT}px`,
        }}>
          <Image width={HOME_PAGE_IMAGE_WIDTH} height={HOME_PAGE_IMAGE_HEIGHT} className="w-full h-auto max-w-full" src={`${SiteAssetsCDN}/gallery-image-3-thumbnail.jpg`} alt="Background Image"/>
        </div>
        <div className="relative md:top-[150px]">
          <FadeInSection duration={1.5} bounce={0} initialOpacity={0.4} initialOffsetY={80}>
            <div className="flex flex-row gap-6">
              <InstragramLink />
              <YoutubeLink />
            </div>
            <h1 className="md:leading-[50px] my-10 text-4xl md:text-6xl font-serif">{translations('indexPageTitle')}</h1>
            <p className="text-base md:text-lg">{translations('indexPageDescription')}</p>
            <div className="mt-4 md:mt-6">
              <PrimaryButton href={pageRoutes.Contact}>{translations('indexPageContactLink')}</PrimaryButton>
            </div>
          </FadeInSection>
        </div>
      </div>
    </>);
};
HomePage.displayName = 'HomePage';
export default HomePage;

    async function __Next_Translate__getStaticProps__1923b459e6c__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1923b459e6c__ as getStaticProps }
  